import { codeStyle } from 'src/utils/code-style';
import httpServices, { AppContext } from './httpServices';
import { DataHelper } from './dataHelper';
import { Url } from './http';

const apiUrl = Url.baseUrl;

const tokenKey = 'token';
const tokenExpire = "token_expire"

export function getBearerToken() {
    return localStorage.getItem(tokenKey);
}

httpServices.setAccessToken(getBearerToken());

export async function login(username, password) {
    const qs = require('qs');
    const data = {
        "code": username,
        "password": password
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    };

    AppContext.userDetails = undefined;

    const { data: token } = await httpServices.post(apiUrl + '/Login', data);
    localStorage.setItem(tokenKey, token.token);
    localStorage.setItem(tokenExpire, token['.expires']);

    httpServices.setAccessToken(getBearerToken());

    if (data != undefined) {
        //alert(JSON.stringify(data))
        AppContext.userDetails = data;
    }
}

export function logout() {
    localStorage.removeItem(tokenKey);
    httpServices.setAccessToken(getBearerToken());

}
export function isAuthenticated() {
    console.log('isAuthenticated');
    return !!getBearerToken();
}

export function changePassword(currentPassword, newPassword) {
    return httpServices.get(`${apiUrl}/Users/ChangePassword?CurrentPassword=${currentPassword}&NewPassword=${newPassword}`);
}

export default {
    login,
    logout,
    getBearerToken,
    isAuthenticated,
    changePassword,
};
