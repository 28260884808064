// import { Loyalty } from "@mui/icons-material";
// import { customer } from "src/api/customers/data";

export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    academy: 'nav.academy',
    account: 'nav.account',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    blog: 'nav.blog',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    customers: 'nav.customers',
    country: 'nav.country',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    ecommerce: 'nav.ecommerce',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    verifyCode: 'nav.verifyCode',
    createCountry: 'nav.createCountry',
    settings: 'nav.settings',
    region: 'nav.region',
    customerType: 'nav.customerType',
    giftCards: 'nav.giftCards',
    tax: 'nav.tax',
    store: 'nav.store',
    location: 'nav.location',
    expensesType: 'nav.expensesType',
    receivedType: 'nav.receivedType',
    restaurant: 'nav.restaurant',
    menuCategory: 'nav.menuCategory',
    unit: 'nav.unit',
    attributes: 'nav.attributes',
    attributesValues: 'nav.attributesValues',
    menu: 'nav.menu',
    floorTable: 'nav.floorTable',
    loyaltyPoint: 'nav.loyaltyPoint',
    discount: 'nav.discount',
    customer: 'nav.customer',
    customerCategory: 'nav.customercategory',
    commonSetting: 'nav.commonSetting',
    paymentType: 'nav.paymentType',
    giftCard: 'nav.giftCard',
    custGiftCard: 'nav.custGiftCard',
    promotionReward: 'nav.promotionReward',
    promotionDiscount: 'nav.promotionDiscount',
    loyaltyPointRewardItem: 'nav.loyaltyPointRewardItem',
    salesReports: 'nav.salesReports',
    salesByDay: 'nav.salesByDay',
    salesByMenu: 'nav.salesByMenu',
    salesByPaymentType: 'nav.salesByPaymentType',
    salesByCategory: 'nav.salesByCategory',
    salesByInvoice: 'nav.salesByInvoice',
    salesByCustomer: 'nav.salesByCustomer',
    salesByCashier: 'nav.salesByCashier',
    cashierInOutReports: 'nav.cashierInOutReports',
    salesByDineIn: 'nav.salesByDineIn',
    user: 'nav.user',
    
  }
};
