import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';

// const IndexPage = lazy(() => import('src/pages/restaurant/index'));


// restaurant
const MenuCatListPage = lazy(() => import('src/pages/restaurant/menuCategory/menuCategoryList'));
const MenuCatCreatePage = lazy(() => import('src/pages/restaurant/menuCategory/createMenuCategory'));
const UnitListPage = lazy(() => import('src/pages/restaurant/unit/unitList'));
const UnitCreatePage = lazy(() => import('src/pages/restaurant/unit/createUnit'));
const AttributesListPage = lazy(() => import('src/pages/restaurant/attributes/attributesList'));
const AttributesCreatePage = lazy(() => import('src/pages/restaurant/attributes/createAttributes'));
const FloorListPage = lazy(() => import('src/pages/restaurant/floor/floorList'));
const FloorCreatePage = lazy(() => import('src/pages/restaurant/floor/createFloor'));
const MenuListPage = lazy(() => import('src/pages/restaurant/menu/menuList'));
const MenuCreatePage = lazy(() => import('src/pages/restaurant/menu/createMenu'));
const PromotionListPage = lazy(() => import('src/pages/restaurant/promotionReward/promotionRewardList'));
const PromotionCreatePage = lazy(() => import('src/pages/restaurant/promotionReward/createPromotionReward'));
const LoyaltyPointRewardListPage = lazy(() => import('src/pages/restaurant/loyaltyPointReward/loyaltyPointRewardList'));
const LoyaltyPointRewardCreatePage = lazy(() => import('src/pages/restaurant/loyaltyPointReward/createLoyaltyPointReward'));

//Customer
const CustCatListPage = lazy(() => import('src/pages/customer/customerCategory/customerCategoryList'));
const CustCatCreatePage = lazy(() => import('src/pages/customer/customerCategory/createCustomerCategory'));
const CustListPage = lazy(() => import('src/pages/customer/customer/customerList'));
const CustCreatePage = lazy(() => import('src/pages/customer/customer/createCustomer'));
const CustGiftCardListPage = lazy(() => import('src/pages/customer/custGiftCard/custGiftCardList'));
const CustCreateGiftCardPage = lazy(() => import('src/pages/customer/custGiftCard/createCustGiftCard'));

// salesReport
const SalesByDatePage = lazy(() => import('src/pages/salesReports/salesByDay'));
const SalesByMenuPage = lazy(() => import('src/pages/salesReports/salesByMenu'));
const SalesByPaymentTypePage = lazy(() => import('src/pages/salesReports/salesByPaymentType'));
const SalesByCategoryPage = lazy(() => import('src/pages/salesReports/salesByCategory'));
const SalesByInvoicePage = lazy(() => import('src/pages/salesReports/salesByInvoice'));
const SalesByCustomerPage = lazy(() => import('src/pages/salesReports/salesByCustomer'));
const SalesByCashierPage = lazy(() => import('src/pages/salesReports/salesByCashier'));
const SalesByDineInPage = lazy(() => import('src/pages/salesReports/salesByDineIn'));
const CashierInOutPage = lazy(() => import('src/pages/salesReports/cashierInOutReports'));

export const restaurantRoutes = [
  {
    path: 'restaurant',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      // {
      //   index: true,
      //   element: <IndexPage />
      // },
      {
        path: 'menuCategory',
        children: [
          {
            index: true,
            element: <MenuCatListPage />
          },
          {
            path: 'createMenuCategory',
            element: <MenuCatCreatePage />
          },
        ]
      },
      {
        path: 'unit',
        children: [
          {
            index: true,
            element: <UnitListPage />
          },
          {
            path: 'createUnit',
            element: <UnitCreatePage />
          }
        ]
      },
      {
        path: 'attributes',
        children: [
          {
            index: true,
            element: <AttributesListPage />
          },
          {
            path: 'createAttributes',
            element: <AttributesCreatePage />
          }
        ]
      },
      {
        path: 'floor',
        children: [
          {
            index: true,
            element: <FloorListPage />
          },
          {
            path: 'createFloor',
            element: <FloorCreatePage />
          }
        ]
      },
      {
        path: 'menu',
        children: [
          {
            index: true,
            element: <MenuListPage />
          },
          {
            path: 'createMenu',
            element: <MenuCreatePage />
          }
        ]
      },
      {
        path: 'promotionReward',
        children: [
          {
            index: true,
            element: <PromotionListPage />
          },
          {
            path: 'createPromotionReward',
            element: <PromotionCreatePage />
          }
        ]
      },
      {
        path: 'loyaltyPointReward',
        children: [
          {
            index: true,
            element: <LoyaltyPointRewardListPage />
          },
          {
            path: 'createLoyaltyPointReward',
            element: <LoyaltyPointRewardCreatePage />
          }
        ]
      },
      {
        path: 'customerCategory',
        children: [
          {
            index: true,
            element: <CustCatListPage />
          },
          {
            path: 'createCustomerCategory',
            element: <CustCatCreatePage />
          }
        ]
      },
      {
        path: 'customer',
        children: [
          {
            index: true,
            element: <CustListPage />
          },
          {
            path: 'createCustomer',
            element: <CustCreatePage />
          }
        ]
      },
      {
        path: 'custGiftCard',
        children: [
          {
            index: true,
            element: <CustGiftCardListPage />
          },
          {
            path: 'createCustGiftCard',
            element: <CustCreateGiftCardPage />
          }
        ]
      },

      //reports
      {
        path: 'salesReports',
        children: [
          {
            index: true,
            element: <SalesByDatePage />
          },
          {
            path: 'salesByMenu',
            element: <SalesByMenuPage />
          },
          {
            path: 'salesByPaymentType',
            element: <SalesByPaymentTypePage />
          },
          {
            path: 'salesByCategory',
            element: <SalesByCategoryPage />
          },
          {
            path: 'salesByInvoice',
            element: <SalesByInvoicePage />
          },
          {
            path: 'salesByCustomer',
            element: <SalesByCustomerPage />
          },
          {
            path: 'salesByCashier',
            element: <SalesByCashierPage />
          },
          {
            path: 'cashierInOutReports',
            element: <CashierInOutPage />
          },
          {
            path: 'salesByDineIn',
            element: <SalesByDineInPage />
          },
        ]
      },  
  
    ]
  }
];
