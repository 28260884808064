import { tokens } from '../tokens';

export const es = {
  [tokens.common.languageChanged]: 'Se ha cambiado el idioma',
  [tokens.nav.academy]: 'Academia',
  [tokens.nav.account]: 'Cuenta',
  [tokens.nav.analytics]: 'Analítica',
  [tokens.nav.auth]: 'Autenticación',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Buscar',
  [tokens.nav.calendar]: 'Calendario',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Pago',
  [tokens.nav.concepts]: 'Conceptos',
  [tokens.nav.contact]: 'Contacto',
  [tokens.nav.course]: 'Curso',
  [tokens.nav.create]: 'Crear',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Clientes',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Detalles',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Editar',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Fuente social',
  [tokens.nav.fileManager]: 'Administrador de Archivos',
  [tokens.nav.finance]: 'Finanzas',
  [tokens.nav.fleet]: 'Parque',
  [tokens.nav.forgotPassword]: 'Recuperar Contraseña',
  [tokens.nav.invoiceList]: 'Facturas',
  [tokens.nav.jobList]: 'Listado de Trabajos',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'Lista',
  [tokens.nav.login]: 'Acceso',
  [tokens.nav.logistics]: 'Logística',
  [tokens.nav.mail]: 'Correo',
  [tokens.nav.management]: 'Gestión',
  [tokens.nav.orderList]: 'Pedidos',
  [tokens.nav.overview]: 'Visión general',
  [tokens.nav.pages]: 'Páginas',
  [tokens.nav.postCreate]: 'Crear Articulo',
  [tokens.nav.postDetails]: 'Detalles del Articulo',
  [tokens.nav.postList]: 'Lista de Articulos',
  [tokens.nav.pricing]: 'Precios',
  [tokens.nav.productList]: 'Productos',
  [tokens.nav.profile]: 'Perfil',
  [tokens.nav.register]: 'Registrarse',
  [tokens.nav.resetPassword]: 'Restablecer Contraseña',
  [tokens.nav.socialMedia]: 'Redes Sociales',
  [tokens.nav.verifyCode]: 'Verificar Código',
  [tokens.nav.country]: 'Country',
  [tokens.nav.createCountry]: 'Create Country',
  [tokens.nav.settings]: 'Setings',
  [tokens.nav.region]: 'Region',
  [tokens.nav.tax]: 'Tax',
  [tokens.nav.store]: 'Store/Company',
  [tokens.nav.expensesType]: 'Expenses Type',
  [tokens.nav.receivedType]: 'Received Type',
  [tokens.nav.restaurant]: 'Restaurants',
  [tokens.nav.menuCategory]: 'Menu Category',
  [tokens.nav.unit]: 'Unit',
  [tokens.nav.attributes]: 'Menu Attributes',
  [tokens.nav.menu]: 'Menu',
  [tokens.nav.attributesValues]: 'Menu Attributes & Values',
  [tokens.nav.floorTable]: 'Floor & Table',
  [tokens.nav.loyaltyPoint]: 'Loyalty Point',
  [tokens.nav.discount]: 'Discount',
  [tokens.nav.customerCategory]: 'Customer Category',
  [tokens.nav.customer]: 'Customer',
  [tokens.nav.commonSetting]: 'Common Settings',
  [tokens.nav.location]: 'Location & Workstation',
  [tokens.nav.paymentType]: 'PaymentType',
  [tokens.nav.giftCard]: 'Gift Card',
  [tokens.nav.custGiftCard]: 'Customer Gift Card',
  [tokens.nav.promotionReward]: 'Promotion & Discount Reward',
  [tokens.nav.loyaltyPointRewardItem]: 'Loyalty Point Reward Item',
  [tokens.nav.salesByDay]: 'Sales By Day', 
  [tokens.nav.salesReports]: 'Sales Reports', 
  [tokens.nav.salesByMenu]: 'Sales By Menu', 
  [tokens.nav.salesByPaymentType]: 'Sales By PaymentType', 
  [tokens.nav.salesByCategory]: 'Sales By Category', 
  [tokens.nav.salesByInvoice]: 'Sales By Invoice', 
  [tokens.nav.salesByCustomer]: 'Sales By Customer', 
  [tokens.nav.salesByCashier]: 'Sales By Cashier', 
  [tokens.nav.promotionDiscount]: 'Promotion & Discount',
  [tokens.nav.cashierInOutReports]: 'Cashier In-Out Reports', 
  [tokens.nav.salesByDineIn]: 'Sales By Dine-In', 
  [tokens.nav.user]: 'User', 
};
