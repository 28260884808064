import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// Country
const CountryListPage = lazy(() => import('src/pages/dashboard/country/list'));
const CountryCreatePage = lazy(() => import('src/pages/dashboard/country/createCountry'));

const UserListPage = lazy(() => import('src/pages/dashboard/user/userList'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/createUser'));


const RegionListPage = lazy(() => import('src/pages/dashboard/region/regionList'));
const RegionCreatePage = lazy(() => import('src/pages/dashboard/region/createRegion'));

const TaxListPage = lazy(() => import('src/pages/dashboard/tax/taxList'));
const TaxCreatePage = lazy(() => import('src/pages/dashboard/tax/createTax'));

const StoreListPage = lazy(() => import('src/pages/dashboard/store/storeList'));
const StoreCreatePage = lazy(() => import('src/pages/dashboard/store/createStore'));

const ExpensesTypeListPage = lazy(() => import('src/pages/dashboard/expensesType/expensesTypeList'));
const ExpensesTypeCreatePage = lazy(() => import('src/pages/dashboard/expensesType/createExpensesType'));

const ReceivedTypeListPage = lazy(() => import('src/pages/dashboard/receivedType/receivedTypeList'));
const ReceivedTypeCreatePage = lazy(() => import('src/pages/dashboard/receivedType/createReceivedType'));

const LoyaltyPointListPage = lazy(() => import('src/pages/dashboard/loyaltyPoint/loyaltyPointList'));
const LoyaltyPointCreatePage = lazy(() => import('src/pages/dashboard/loyaltyPoint/createLoyaltyPoint'));

const DiscountListPage = lazy(() => import('src/pages/dashboard/discount/discountList'));
const DiscountCreatePage = lazy(() => import('src/pages/dashboard/discount/createDiscount'));

const LocationListPage = lazy(() => import('src/pages/dashboard/location/locationList'));
const LocationCreatePage = lazy(() => import('src/pages/dashboard/location/createLocation'));

const PaymentTypeListPage = lazy(() => import('src/pages/dashboard/paymentType/paymentTypeList'));
const PaymentTypeCreatePage = lazy(() => import('src/pages/dashboard/paymentType/createPaymentType'));

const GiftCardListPage = lazy(() => import('src/pages/dashboard/giftCard/giftCardList'));
const GiftCardCreatePage = lazy(() => import('src/pages/dashboard/giftCard/createGiftCard'));

// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

// Order
const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));



export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'user',
        children: [
          {
            index: true,
            element: <UserListPage />
          },
          {
            path: 'createUser',
            element: <UserCreatePage />
          }
        ]
      },
      {
        path: 'country',
        children: [
          {
            index: true,
            element: <CountryListPage />
          },
          {
            path: 'createCountry',
            element: <CountryCreatePage />
          }
        ]
      },
      {
        path: 'region',
        children: [
          {
            index: true,
            element: <RegionListPage />
          },
          {
            path: 'createRegion',
            element: <RegionCreatePage />
          }
        ]
      },
      {
        path: 'tax',
        children: [
          {
            index: true,
            element: <TaxListPage />
          },
          {
            path: 'createTax',
            element: <TaxCreatePage />
          }
        ]
      },
      {
        path: 'store',
        children: [
          {
            index: true,
            element: <StoreListPage />
          },
          {
            path: 'createStore',
            element: <StoreCreatePage />
          }
        ]
      },
      {
        path: 'location',
        children: [
          {
            index: true,
            element: <LocationListPage />
          },
          {
            path: 'createLocation',
            element: <LocationCreatePage />
          }
        ]
      },
      {
        path: 'paymentType',
        children: [
          {
            index: true,
            element: <PaymentTypeListPage />
          },
          {
            path: 'createPaymentType',
            element: <PaymentTypeCreatePage />
          }
        ]
      },
      {
        path: 'expensesType',
        children: [
          {
            index: true,
            element: <ExpensesTypeListPage />
          },
          {
            path: 'createExpensesType',
            element: <ExpensesTypeCreatePage />
          }
        ]
      },
      {
        path: 'receivedType',
        children: [
          {
            index: true,
            element: <ReceivedTypeListPage />
          },
          {
            path: 'createReceivedType',
            element: <ReceivedTypeCreatePage />
          }
        ]
      },
      {
        path: 'loyaltyPoint',
        children: [
          {
            index: true,
            element: <LoyaltyPointListPage />
          },
          {
            path: 'createLoyaltyPoint',
            element: <LoyaltyPointCreatePage />
          }
        ]
      },
      {
        path: 'giftCard',
        children: [
          {
            index: true,
            element: <GiftCardListPage />
          },
          {
            path: 'createGiftCard',
            element: <GiftCardCreatePage />
          }
        ]
      },
      {
        path: 'discount',
        children: [
          {
            index: true,
            element: <DiscountListPage />
          },
          {
            path: 'createDiscount',
            element: <DiscountCreatePage />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <OrderListPage />
          },
          {
            path: ':orderId',
            element: <OrderDetailPage />
          }
        ]
      },

    ]
  }
];
