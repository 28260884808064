import { tokens } from '../tokens';

export const de = {
  [tokens.common.languageChanged]: 'Sprache geändert',
  [tokens.nav.academy]: 'Akademie',
  [tokens.nav.account]: 'Konto',
  [tokens.nav.analytics]: 'Analytik',
  [tokens.nav.auth]: 'Authentifizierung',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Durchsuche',
  [tokens.nav.calendar]: 'Kalender',
  [tokens.nav.chat]: 'Plaudern',
  [tokens.nav.checkout]: 'Auschecken',
  [tokens.nav.concepts]: 'Konzepte',
  [tokens.nav.contact]: 'Kontakt',
  [tokens.nav.course]: 'Kurs',
  [tokens.nav.create]: 'Schaffen',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Kunden',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Einzelheiten',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Bearbeiten',
  [tokens.nav.error]: 'Fehler',
  [tokens.nav.feed]: 'Füttern',
  [tokens.nav.fileManager]: 'Dateimanager',
  [tokens.nav.finance]: 'Finanzen',
  [tokens.nav.fleet]: 'Flotte',
  [tokens.nav.forgotPassword]: 'Passwort Vergessen',
  [tokens.nav.invoiceList]: 'Rechnungen',
  [tokens.nav.jobList]: 'Stellenangebote',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'Aufführen',
  [tokens.nav.login]: 'Anmeldung',
  [tokens.nav.logistics]: 'Logistik',
  [tokens.nav.mail]: 'E-Mail',
  [tokens.nav.management]: 'Verwaltung',
  [tokens.nav.orderList]: 'Aufträge',
  [tokens.nav.overview]: 'Überblick',
  [tokens.nav.pages]: 'Seiten',
  [tokens.nav.postCreate]: 'Beitrag erstellen',
  [tokens.nav.postDetails]: 'Beitragsdetails',
  [tokens.nav.postList]: 'Beitragsliste',
  [tokens.nav.pricing]: 'Preisgestaltung',
  [tokens.nav.productList]: 'Produkte',
  [tokens.nav.profile]: 'Profil',
  [tokens.nav.register]: 'Registrieren',
  [tokens.nav.resetPassword]: 'Passwort Zurücksetzen',
  [tokens.nav.socialMedia]: 'Sozialen Medien',
  [tokens.nav.verifyCode]: 'Code Überprüfen',
  [tokens.nav.country]: 'Country',
  [tokens.nav.createCountry]: 'Create Country',
  [tokens.nav.settings]: 'Setings',
  [tokens.nav.region]: 'Region',
  [tokens.nav.tax]: 'Tax',
  [tokens.nav.store]: 'Store/Company',
  [tokens.nav.expensesType]: 'Expenses Type',
  [tokens.nav.receivedType]: 'Received Type',
  [tokens.nav.restaurant]: 'Restaurants',
  [tokens.nav.menuCategory]: 'Menu Category',
  [tokens.nav.unit]: 'Unit',
  [tokens.nav.attributes]: 'Menu Attributes',
  [tokens.nav.menu]: 'Menu',
  [tokens.nav.attributesValues]: 'Menu Attributes & Values',
  [tokens.nav.floorTable]: 'Floor & Table',
  [tokens.nav.loyaltyPoint]: 'Loyalty Point',
  [tokens.nav.discount]: 'Discount',
  [tokens.nav.customer]: 'Customer',
  [tokens.nav.customerCategory]: 'Customer Category',
  [tokens.nav.commonSetting]: 'Common Settings',
  [tokens.nav.location]: 'Location & Workstation',
  [tokens.nav.paymentType]: 'PaymentType',
  [tokens.nav.giftCard]: 'Gift Card',
  [tokens.nav.custGiftCard]: 'Customer Gift Card',
  [tokens.nav.promotionReward]: 'Promotion & Discount Reward',
  [tokens.nav.promotionDiscount]: 'Promotion & Discount',
  [tokens.nav.loyaltyPointRewardItem]: 'Loyalty Point Reward Item',
  [tokens.nav.salesByDay]: 'Sales By Day', 
  [tokens.nav.salesReports]: 'Sales Reports', 
  [tokens.nav.salesByPaymentType]: 'Sales By PaymentType', 
  [tokens.nav.salesByCategory]: 'Sales By Category', 
  [tokens.nav.salesByInvoice]: 'Sales By Invoice', 
  [tokens.nav.salesByCustomer]: 'Sales By Customer', 
  [tokens.nav.salesByCashier]: 'Sales By Cashier', 
  [tokens.nav.cashierInOutReports]: 'Cashier In-Out Reports', 
  [tokens.nav.salesByDineIn]: 'Sales By Dine-In', 
  [tokens.nav.user]: 'User', 
};
