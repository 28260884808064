import * as Yup from 'yup';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/components/router-link';
import { Seo } from 'src/components/seo';
import { useAuth } from 'src/hooks/use-auth';
import { useMounted } from 'src/hooks/use-mounted';
import { usePageView } from 'src/hooks/use-page-view';
import { useRouter } from 'src/hooks/use-router';
import { useSearchParams } from 'src/hooks/use-search-params';
import { paths } from 'src/paths';
import { AuthIssuer } from 'src/sections/auth/auth-issuer';
import { Box, Container, Grid } from '@mui/material';

const initialValues = {
  email: '',
  password: '',
  submit: null
};

const validationSchema = Yup.object({
  email: Yup
    .string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup
    .string()
    .max(255)
    .required('Password is required')
});

const Page = () => {
  const isMounted = useMounted();
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const { issuer, signIn } = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await signIn(values.email, values.password);

        if (isMounted()) {
          router.push(returnTo || paths.dashboard.index);
        }
      } catch (err) {
        console.error(err);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  usePageView();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh',border:"0px solid #fff" }}
      >
        <Grid item xs={3}>
          <Container maxWidth="sm"
            sx={{
              border: "0px solid #fff",
              minWidth:"50vh",
            }}
          >
            <Seo title="Login" />
            <div>
              <Card elevation={16} sx={{minHeight:"50vh"}}>
                <CardHeader
                  subheader={(
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {/* Don&apos;t have an account?
                      &nbsp; */}
                      <Link
                        component={RouterLink}
                        href={paths.auth.jwt.register}
                        underline="hover"
                        variant="subtitle2"
                      >
                        {/* Register */}
                      </Link>
                    </Typography>
                  )}
                  sx={{ pb: 0 }}
                  title="Log in"
                />
                <CardContent>
                  <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <Stack spacing={3}>
                      <TextField
                        autoFocus
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label="Email Address"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.email}
                      />
                      <TextField
                        error={!!(formik.touched.password && formik.errors.password)}
                        fullWidth
                        helperText={formik.touched.password && formik.errors.password}
                        label="Password"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="password"
                        value={formik.values.password}
                      />
                    </Stack>
                    {formik.errors.submit && (
                      <FormHelperText
                        error
                        sx={{ mt: 3 }}
                      >
                        {formik.errors.submit}
                      </FormHelperText>
                    )}
                    <Button
                      disabled={formik.isSubmitting}
                      fullWidth
                      size="large"
                      sx={{ mt: 7 }}
                      type="submit"
                      variant="contained"
                    >
                      Log In
                    </Button>
                  </form>
                </CardContent>
              </Card>
              <Stack
                spacing={3}
                sx={{ mt: 3 }}
              >
                {/* <Alert severity="error">
                  <div>
                    You can use <b>demo@devias.io</b> and password   <b>Password123!</b>
                  </div>
                </Alert> */}
                {/* <AuthIssuer issuer={issuer} /> */}
              </Stack>
            </div>
          </Container>
        </Grid>
      </Box>
    </>
  );
};

export default Page;
