import { tokens } from '../tokens';

export const en = {
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Account',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.country]: 'Country',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Details',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'Verify Code',
  [tokens.nav.createCountry]: 'Create Country',
  [tokens.nav.settings]: 'Setings',
  [tokens.nav.region]: 'Region',
  [tokens.nav.tax]: 'Tax',
  [tokens.nav.store]: 'Store/Company',
  [tokens.nav.expensesType]: 'Expenses Type',
  [tokens.nav.receivedType]: 'Received Type',
  [tokens.nav.restaurant]: 'Restaurants',
  [tokens.nav.menuCategory]: 'Menu Category',
  [tokens.nav.unit]: 'Unit',
  [tokens.nav.attributes]: 'Menu Attributes',
  [tokens.nav.menu]: 'Menu',
  [tokens.nav.attributesValues]: 'Menu Attributes & Values',
  [tokens.nav.floorTable]: 'Floor & Table',
  [tokens.nav.loyaltyPoint]: 'Loyalty Point',
  [tokens.nav.discount]: 'Discount',
  [tokens.nav.customerCategory]: 'Customer Category',
  [tokens.nav.customer]: 'Customer',
  [tokens.nav.commonSetting]: 'Common Settings',
  [tokens.nav.location]: 'Location & Workstation',
  [tokens.nav.paymentType]: 'PaymentType',
  [tokens.nav.giftCard]: 'Gift Card',
  [tokens.nav.custGiftCard]: 'Customer Gift Card',
  [tokens.nav.promotionReward]: 'Promotion & Discount Reward',
  [tokens.nav.loyaltyPointRewardItem]: 'Loyalty Point Reward Item',
  [tokens.nav.salesByDay]: 'Sales By Day', 
  [tokens.nav.salesReports]: 'Sales Reports', 
  [tokens.nav.salesByMenu]: 'Sales By Menu', 
  [tokens.nav.salesByPaymentType]: 'Sales By PaymentType', 
  [tokens.nav.salesByCategory]: 'Sales By Category', 
  [tokens.nav.salesByInvoice]: 'Sales By Invoice', 
  [tokens.nav.salesByCustomer]: 'Sales By Customer', 
  [tokens.nav.salesByCashier]: 'Sales By Cashier', 
  [tokens.nav.promotionDiscount]: 'Promotion & Discount',
  [tokens.nav.cashierInOutReports]: 'Cashier In-Out Reports', 
  [tokens.nav.salesByDineIn]: 'Sales By Dine-In', 
  [tokens.nav.user]: 'User', 
};
