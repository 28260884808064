import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import { ReportOutlined, Restaurant, Settings } from '@mui/icons-material';
import { DataHelper } from 'src/services/dataHelper';
import toast from 'react-hot-toast';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';

export const useSections = () => {
  const { t } = useTranslation();

  const [menu, setMenu] = useState([{}]);
  const [count, setCount] = useState(0);
  const [searching, setSearching] = useState('');
  const [searchData, setSearchData] = useState([]);

  const bind = async () => {
    try {
      const res = await new DataHelper().getList('Menus', 1000000, 1);
      if (res) {
        //alert(JSON.stringify( res.data.list));
        const menus = res.data.list;
        const parentMenus = menus.filter(x => x.parentId === 0);
      //  alert(JSON.stringify(parentMenus));
        if (parentMenus != null && parentMenus.length != 0) {
          for (var PDetails of parentMenus) {
            const parentId = PDetails.ID;

            if (parentId !== 0) {
              const subMenus = menus.filter(x => x.parentId === parentId);
              
            }
          }
        }

       // const reCreateMenu=JSON.stringify(parentMenus);

       // alert(JSON.stringify(parentMenus));

        setMenu(res.data.list);
        setSearchData(res.data.list);
        setCount(res.data.total);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
   // bind()
  }, []);

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.dashboard),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          //settings
          {
            title: t(tokens.nav.commonSetting),
            path: paths.dashboard.country.index,
            icon: (
              <SvgIcon fontSize="small">
                <Settings />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.user),
                path: paths.dashboard.user.userList
              },
              {
                title: t(tokens.nav.store),
                path: paths.dashboard.store.storeList
              },
              {
                title: t(tokens.nav.country),
                path: paths.dashboard.country.index
              },
              {
                title: t(tokens.nav.region),
                path: paths.dashboard.region.regionList
              },
              {
                title: t(tokens.nav.tax),
                path: paths.dashboard.tax.taxList
              },

              {
                title: t(tokens.nav.location),
                path: paths.dashboard.location.locationList
              },
              {
                title: t(tokens.nav.paymentType),
                path: paths.dashboard.paymentType.paymentTypeList
              },
              {
                title: t(tokens.nav.expensesType),
                path: paths.dashboard.expensesType.expensesTypeList
              },
              {
                title: t(tokens.nav.receivedType),
                path: paths.dashboard.receivedType.receivedTypeList
              },
              {
                title: t(tokens.nav.loyaltyPoint),
                path: paths.dashboard.loyaltyPoint.loyaltyPointList
              },
              {
                title: t(tokens.nav.giftCard),
                path: paths.dashboard.giftCard.giftCardList
              },
              {
                title: t(tokens.nav.promotionDiscount),
                path: paths.dashboard.discount.discountList
              },
            ]
          },

          // restaurant
          {
            title: t(tokens.nav.restaurant),
            path: paths.restaurant.menuCategory.index,
            icon: (
              <SvgIcon fontSize="small">
                <Restaurant />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.unit),
                path: paths.restaurant.unit.unitList
              },
              {
                title: t(tokens.nav.menuCategory),
                path: paths.restaurant.menuCategory.menuCategoryList
              },

              {
                title: t(tokens.nav.attributesValues),
                path: paths.restaurant.attributes.attributesList
              },

              {
                title: t(tokens.nav.menu),
                path: paths.restaurant.menu.menuList
              },
              {
                title: t(tokens.nav.floorTable),
                path: paths.restaurant.floor.floorList
              },
              {
                title: t(tokens.nav.promotionReward),
                path: paths.restaurant.promotionReward.promotionRewardList
              },
              {
                title: t(tokens.nav.loyaltyPointRewardItem),
                path: paths.restaurant.loyaltyPointReward.loyaltyPointRewardList
              },
            ]
          },

          //customer------------
          {
            title: t(tokens.nav.customer),
            path: paths.restaurant.customerCategory.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.customerCategory),
                path: paths.restaurant.customerCategory.customerCategoryList
              },
              {
                title: t(tokens.nav.customer),
                path: paths.restaurant.customer.customerList
              },
              {
                title: t(tokens.nav.custGiftCard),
                path: paths.restaurant.custGiftCard.custGiftCardList
              },

            ]
          },

          //Sales Reports------------
          {
            title: t(tokens.nav.salesReports),
            path: paths.restaurant.salesReports.index,
            icon: (
              <SvgIcon fontSize="small">
                <ReportOutlined />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.salesByDay),
                path: paths.restaurant.salesReports.salesByDay
              },
              {
                title: t(tokens.nav.salesByCategory),
                path: paths.restaurant.salesReports.salesByCategory
              },
              {
                title: t(tokens.nav.salesByMenu),
                path: paths.restaurant.salesReports.salesByMenu
              },
              {
                title: t(tokens.nav.salesByPaymentType),
                path: paths.restaurant.salesReports.salesByPaymentType
              },
              {
                title: t(tokens.nav.salesByInvoice),
                path: paths.restaurant.salesReports.salesByInvoice
              },

              {
                title: t(tokens.nav.salesByCustomer),
                path: paths.restaurant.salesReports.salesByCustomer
              },
              {
                title: t(tokens.nav.salesByCashier),
                path: paths.restaurant.salesReports.salesByCashier
              },
              {
                title: t(tokens.nav.salesByDineIn),
                path: paths.restaurant.salesReports.salesByDineIn
              },
              {
                title: t(tokens.nav.cashierInOutReports),
                path: paths.restaurant.salesReports.cashierInOutReports
              },
            ]
          },

        ]
      },
    ];
  }, [t]);
};
